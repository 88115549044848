* {
    box-sizing: border-box;
}

body {
    margin: 0;

}

:root {
    --font-logo: 'Dancing Script', cursive;
    --font-heading: 'Roboto', sans-serif;
    --font-body: 'Lato', sans-serif;
    --color-logo: #ddab51;
    --bgcolor-header: #1b0432;
    --color-main: #000000;
    --color-secondary: #ffffff;
    --bgcolor-secondary: #fffef4;
    --button-hover: #e3b95c;
    --placeholder-color: #cc8231;
    --input-border-color: #c9c2e8;
  }

.canvas {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-body);
    color: var(--color-main);
}

/* Header */

.Header {
    height: 70px;
    font-family: var(--font-heading);
    border-bottom: 1px solid var(--color-logo)
}

/* Hero */
.Hero {
    text-align: center;
    min-height: 80vh;
    width: 100%;
    background-color: var(--bgcolor-secondary);
}

.slide {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
}

.content {
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat; 
    color: var(--color-secondary);
    padding: 20px;
    min-height: 70vh; 
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.content h1, 
.content h2, 
.content p {
    line-height: 1.9;
    max-width: 70%;
}

.content h1 {
    font-size: 42px;
}

.content h2 {
    font-size: 24px;
}

.content .text {
    width: 60%;
    font-size: 22px;
    width: 100%;
    transition: all 0.8s ease-in-out;
}

.content .text:hover {
    color: var(--button-hover);
    border: 2px solid var(--input-border-color);
    border-radius: 4px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.content span {
    font-weight: 700;
    color: var(--button-hover);
}

.content-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.source {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    text-align: center;
    width: 100%;
}

.source p {
    margin: 0;
}

.source a {
    text-decoration: none;
    color: var(--color-secondary);
}

.slide-1 .content {
    background-image: url(./assets/slide_1.png);
}

.slide-2 .content {
    background-image: url(./assets/slide_2.png); 
}

.slide-3 .content {
    background-image: url(./assets/slide_3.png);
}

.slide-4 .content {
    background-image: url(./assets/slide_4.png);
}

.slide-5 .content {
    background-image: url(./assets/slide_5.png); 
}


@keyframes fadeInUpHighlight {
    0% {
      opacity: 0;
      transform: translateY(20px);
      color: var(--color-secondary);
    }
    70% {
      opacity: 1;
      transform: translateY(0);
      color: var(--button-hover);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
      color: var(--color-secondary);
      font-size: 50px;
    }
  }
  
  .slide-2.animate .content h1 {
    opacity: 0;
    animation: fadeInUpHighlight 3s ease-in-out forwards; /* Dłuższa animacja dla lepszego efektu */
  }
  

@media (max-width: 1180px) {
    .content {
        padding: 0;
    }
}

@media (max-width: 826px) {
    .content h1 {
        font-size: 32px;
    }

    .content h2 {
        font-size: 24px;
    }

    .content .text {
        font-size: 18px;
    }
}

@media (max-width: 660px) {
    .content h1 {
        font-size: 32px;
    }

    .content h2 {
        font-size: 22px;
    }

    .content .text {
        font-size: 16px;
    }

    .slide .source {
        display: none;
    }
}

@media (max-width: 585px) {
    .content h1 {
        font-size: 22px;
    }

    .content h2 {
        font-size: 20px;
    }

    .content .text {
        font-size: 14px;
    }
}

@media (max-width: 530px) {
    .content h1 {
        font-size: 20px;
    }

    .content h2 {
        font-size: 18px;
    }

    .content .text {
        font-size: 12px;
    }
}

/* For whom */

.css-1m9hr9e {
    background-color: var(--bgcolor-secondary);
    text-align: center;
}

/* Offer */

.Offer {
    background-color: var(--bgcolor-header);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    width: 100%;
    margin-top: 40px;
}

.Offer-paper {
    width: 60%;
    line-height: 1.9;
    padding: 10px 50px;

    h2 {
        text-align: center;
    }

    :nth-child(2),
    :nth-child(3),
    :nth-child(4),
    :nth-child(5),
    :nth-child(6) {
        font-style: italic;
    }

    :nth-child(7) {
        text-align: right;
        font-family: var(--font-logo);
        font-size: 28px;
        line-height: normal;
        margin: 0;
    }

    :nth-child(8) {
        text-align: right;
        line-height: normal;
    }

    span:hover {
       padding-top: 10px;
       padding-bottom: 10px;
       border-radius: 4px;
       background-color: var(--input-border-color);
    }  
}

@media (max-width: 1190px) {
    .Offer {
        height: auto;
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

@media (max-width: 870px) {
    .Offer-paper {
        width:85%;
    }
}

@media (max-width: 430px) {
    .Offer-paper {
        padding: 10px 10px;
    }
}

/* Contact */

#Contact {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 600px;
    padding: 20px;
    width: 100%;
    background-color: var(--bgcolor-secondary);
}

.Contact-box {
    max-width: 400px;

    form {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.Contact-box-item {
    display: flex; 
    justify-content: space-between;
    
    label {
        width: 100px;
    }
    
    input,
    textarea {
        width: 300px;
        border: 1px solid var(--input-border-color);
        border-radius: 4px;
        font-family: var(--font-body);
        
        &::placeholder {
            color: var(--placeholder-color);
        }
    }

    input {
        height: 30px;
    }
}

button {
    background-color: var(--bgcolor-header);
    color: var(--color-secondary);
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-family: var(--font-body);
    font-weight: 700;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

button:hover {
    background-color: var(--button-hover);
    color: var(--bgcolor-header);
}

@media (max-width: 430px) {
    .Contact-box-item {
        label {
            width: 70px;
            font-size: 12px;
        }
        input,
        textarea {
            width: 250px;
        }
    }
}

/* Footer */

.Footer {
    height: auto;
    width: 100%;
    background-color: var(--bgcolor-header);
    padding: 20px;
    color: var(--color-secondary);
    font-size: 14px;
    font-family: var(--font-body);
    p {
        margin: 0;
    }
}

.references-footer {
    height: 100%;
    display: flex;
    align-items: flex-end;
    gap: 8px;
    padding-bottom: 3px;

    button {
        text-decoration: none;
        color: var(--color-secondary);
        background-color: var(--bgcolor-header);
        padding: 0;
        font-size: 12px;

        &:hover {
            color: var(--button-hover);  
        }
    }  
}

.logo-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 3px;
}

.social-media {
    display: flex;
    justify-content: right;
    align-items: flex-end;
    height: 100%;
}

@media (max-width: 1180px) {
    .Footer {
        font-size: 12px;
    }
}

@media (max-width: 1048px) {
    .references-footer {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        p{
            display: none;
        }
    }

    .social-media {
        align-items: center;
        justify-content: flex-end;
    }
}

@media (max-width: 1020px) {
    .references-footer {
        flex-direction: row;
        align-items: center;
        justify-content: center;

        p {
            display: block;
        }
    }

.logo-footer p span {
    display: none;
}
}

/* Privacy Policy and Terms and Conditions */

.PrivacyPolicy,
.TermsAndConditions {
    padding: 60px;
    font-family: var(--font-body);
    
    a {
        color: var(--color-main);
    }
    
    .text {
        background-color: var(--bgcolor-secondary);
        padding: 40px;
        
        span {
            font-weight: 700;
        }
    }

    .text ol,
    .text ul {
      padding-left: 20px;
    
      li {
          margin-left: -5px;
          padding-left: 5px;
      }  
    }
}

.admins-data {
    p {
        margin: 0;
    }
}

@media (max-width: 600px) {
    .PrivacyPolicy,
    .TermsAndConditions {
        padding: 10px;

        .text {
            padding: 10px;
        }
    }
}

/* Page not found */

.PageNotFound {
    height: calc(100vh - 140px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: var(--font-body);
}